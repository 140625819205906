import styled, { css } from 'styled-components';

import { colors } from '../../../configs/theme';

export const DropDown = styled('div')`
    width: ${(props) => props.width};
    position: relative;
    ${(props) =>
        props.margin &&
        css`
            margin: ${props.margin};
        `}
    ${(props) =>
        props.minWidth &&
        css`
            min-width: ${props.minWidth}px;
        `}
    ${(props) =>
        props.flex &&
        css`
            flex: ${props.flex};
        `}

    ${(props) =>
        props.kind &&
        props.kind === 'popup-filtros-input' &&
        css`
            width: 100%;
        `}


    ${({ theme, ...props }) => {
        return props.forceMobile
            ? theme.media([theme.screens.smartphone, theme.screens.tablet])`
            width: 100%;
        `
            : theme.smartphone`
            width: 100%;
        `;
    }}
`;

export const Blanket = styled('div')`
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    position: fixed;
    z-index: 1;
`;

// const shadow = 'hsla(218, 50%, 10%, 0.1)';

export const Menu = styled('div')`
    background-color: ${colors.white};
    border-radius: 4px;
    padding: 10px;
    position: absolute;
    top: 100%;
    left: ${(props) =>
        (props.kind && props.kind === 'filtros') ||
        props.kind === 'popup-filtros-input'
            ? null
            : '15px'};
    width: ${(props) =>
        (props.kind && props.kind === 'filtros') ||
        props.kind === 'popup-filtros-input'
            ? '100%'
            : 'calc(100% - 30px)'};
    z-index: 2;
    box-shadow: 0px 30px 80px rgba(0, 0, 0, 0.3);

    ${({ theme }) => theme.media([theme.screens.smartphone])`
        width: 100%;
        left: 0;
        position: relative;
    `}
`;

export const ItemMulti = styled('div')`
    background-color: hsl(0, 0%, 90%);
    border-radius: 2px;
    display: flex;
    margin: 2px;
    min-width: 0;
    box-sizing: border-box;
`;

export const DescItemMulti = styled('div')`
    border-radius: 2px;
    color: hsl(0, 0%, 20%);
    font-size: 85%;
    overflow: hidden;
    padding: 3px 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
`;
