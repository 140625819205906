import CheckboxGroup from 'react-checkbox-group';

import styled, { css } from 'styled-components';

import { colors } from '../../../configs/theme';
import { parse } from '../../../utils/FontSize';

export const Container = styled('div')`
    width: ${(props) => props.width};
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;
    padding: 0 15px;

    ${({ theme, ...props }) => {
        return props.forceMobile
            ? theme.media([theme.screens.smartphone, theme.screens.tablet])`
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        `
            : theme.smartphone`
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        `;
    }}
`;

export const GroupContainer = styled('div')`
    display: flex;
    flex-wrap: wrap;
    height: auto;
    width: 100%;

    ${({ theme, ...props }) =>
        !props.ignoreMobile &&
        theme.smartphone`
        flex-direction: column;
    `}
`;

export const StyledGroup = styled(CheckboxGroup)`
    display: flex;
    height: auto;
    width: auto;
`;

const buttonStyle = css`
    background: ${colors.grey_lines_table};
    margin-right: 10px;
    border: 1px solid ${colors.grey_pagination};
    color: ${colors.grey_placeholder};
    font-size: ${parse(14)};
    padding: 10px;
    border-radius: 4px;
    line-height: 20px;

    ${(props) =>
        !props.checked &&
        css`
            &:hover {
                border-color: ${colors.grey_placeholder_lighter};
            }
        `}

    p {
        font-weight: bold;
    }

    ${(props) =>
        props.checked &&
        css`
            background-color: ${colors.white};
            border-color: ${colors.primary_blue};

            p {
                color: ${colors.primary_blue};
            }
        `}

    span,
    input {
        display: none;
    }

    ${({ theme, ...props }) =>
        !props.ignoreMobile &&
        theme.smartphone`
        margin-right: 0;
        margin-bottom: 10px;
    `}
`;

export const InputRadio = styled('label')`
    display: flex;
    width: auto;
    margin-right: 20px;
    margin-bottom: 10px;
    height: auto;
    align-items: center;

    ${({ theme, ...props }) => {
        return props.forceMobile
            ? theme.media([theme.screens.smartphone, theme.screens.tablet])`
                width: auto;
            `
            : theme.smartphone`
                width: 100%;
            `;
    }}

    span {
        margin-right: 5px;
        width: 18px;
        height: 18px;
        border: 2px solid ${colors.grey_radio};
        background: ${colors.grey_border_radio};
        border-radius: 50%;

        ${(props) =>
            props.checked &&
            css`
                border: 5px solid #00b2c9;
                background: transparent;
            `}
    }

    p {
        font-size: ${parse(14)};
        color: ${colors.grey_chumbo_icons};
        font-weight: bold;
    }

    input {
        display: none;
    }

    ${(props) => props.buttonstyle && buttonStyle}
`;
