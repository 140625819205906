import React, { useState } from 'react';
import ReactSelect from 'react-select';
import AsyncReactSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';

import PropTypes from 'prop-types';

import { Row } from '../../../app/global-styles';
import { colors } from '../../../configs/theme';
import { parse } from '../../../utils/FontSize';
import Icon from '../../icon';
import ErrorMessage from '../error-message';
import {
    Container,
    IconContainer,
    Label,
    LabelMessage,
    Required,
    StyledInput,
} from '../styles';
import { Blanket, Menu, ItemMulti, DescItemMulti } from './styles';

function Select({
    height,
    background,
    radius,
    width,
    error_message,
    type_select,
    kind,
    flex,
    label,
    required,
    name,
    value,
    id,
    onChange,
    minWidth,
    disabled,
    styled,
    size,
    onBlur,
    isClearable,
    placeholder,
    margin,
    ...props
}) {
    const [isOpen, setIsOpen] = useState(false);
    const icon_color = isOpen ? colors.white : colors.grey_placeholder;
    background = isOpen ? colors.primary_blue : background;

    let input_value = placeholder;

    if (
        isOpen &&
        !value &&
        value &&
        typeof value === 'object' &&
        Object.keys(value).length > 0
    ) {
        input_value = '';
    } else if (value && value.label) {
        input_value = value.label;
    } else if (value && Array.isArray(value) && value.length > 0) {
        input_value = value.map((item) => (
            <ItemMulti key={item.value}>
                <DescItemMulti>{item.label}</DescItemMulti>
            </ItemMulti>
        ));
    }

    const addTheme = (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary: colors.primary_blue,
            primary25: colors.primary_blue_hover_selected,
            primary50: colors.primary_blue,
            primary75: colors.primary_blue_hover,
        },
    });

    const addCustomStyles = {
        container: (provided, state) => ({
            ...provided,
            height: 'auto',
            marginTop: '0',
        }),
        input: (provided, state) => ({
            ...provided,
            marginTop: '0',
        }),
        menu: (provided, state) => ({
            ...provided,
            ...(props.position ? {position: props.position} : {}),
            width: 'calc(100% + 20px)',
            left: '-10px',
            border: 'unset',
            borderRadius: '0 0 5px 5px',
            boxShadow: 'unset',
            padding: '0 10px 8px 10px',
        }),
        option: (provided, { isFocused, isSelected, theme, ...state }) => {
            let color = isFocused
                ? theme.colors.primary
                : colors.grey_chumbo_table;
            color = isSelected ? colors.white : color;
            let backgroundColor = isFocused
                ? theme.colors.primary25
                : colors.white;
            backgroundColor = isSelected
                ? theme.colors.primary
                : backgroundColor;

            return {
                ...provided,
                borderRadius: '5px',
                color,
                wordWrap: 'break-word',
                fontSize: parse(16),
                backgroundColor,
                cursor: 'pointer',
            };
        },
    };

    const select_options = {
        async: AsyncReactSelect,
        creatable: CreatableSelect,
        async_creatable: AsyncCreatableSelect,
        default: ReactSelect,
    };

    const SelectComponent = select_options[type_select];

    const container_props = {
        width,
        onClick: toggleOpen,
        margin,
        disabled,
        minWidth,
        flex,
    };

    const input_props = {
        background,
        radius: '5px',
        width: '100%',
        shadow: true,
        styled,
        disabled,
    };

    if (!value || Object.keys(value).length <= 0) {
        input_props.color = isOpen ? colors.white : colors.grey_placeholder;
    }

    if (error_message) {
        input_props.color = colors.red_error_message;
    }

    function toggleOpen(event) {
        if (
            ((event &&
                event.target &&
                event.target.classList &&
                !Array.from(event.target.classList).includes('icon-close') &&
                !(
                    event &&
                    event.target &&
                    props.isMulti &&
                    event.target.classList &&
                    Array.from(event.target.classList).some((className) =>
                        className.includes('option')
                    )
                )) ||
                !event) &&
            !disabled
        ) {
            setIsOpen(!isOpen);
            if (onBlur && isOpen) onBlur(isOpen);
        }
    }

    function onSelectChange(selected_value) {
        if (onChange) {
            onChange(selected_value);
        }

        if (!props.isMulti) {
            toggleOpen();
        }
    }

    return (
        <Container
            margin="0"
            kind={kind}
            size={size}
            {...container_props}
            position
        >
            {label && (
                <Label htmlFor={id}>
                    <LabelMessage kind={kind}>
                        {label}
                        {required && <Required>*</Required>}
                    </LabelMessage>
                </Label>
            )}
            <Row height={height} relative ignoreMobile>
                <IconContainer>
                    <Icon
                        size="20px"
                        color={icon_color}
                        className="icon-filtros-input"
                        bottom="12px"
                        right="15px"
                        absolute
                    />
                    {isClearable && Object.entries(value).length !== 0 && (
                        <Icon
                            size="20px"
                            color={icon_color}
                            className="icon-close"
                            bottom="12px"
                            onClick={() => {
                                if (onChange && !props.isMulti) onChange({});
                                else if (onChange && props.isMulti)
                                    onChange([]);
                            }}
                            right="45px"
                            hover={colors.red_error_message}
                            absolute
                        />
                    )}
                </IconContainer>
                <StyledInput
                    as="button"
                    padding="10px"
                    select
                    isClearable={isClearable}
                    type="button"
                    error_message={error_message}
                    border="2px solid transparent"
                    {...input_props}
                >
                    {input_value}
                </StyledInput>
                {error_message && (
                    <ErrorMessage select error_message={error_message} />
                )}
            </Row>
            {isOpen && (
                <Menu kind={kind}>
                    <SelectComponent
                        autoFocus
                        backspaceRemovesValue={false}
                        components={{
                            // eslint-disable-next-line react/prop-types
                            DropdownIndicator: ({ isFocused, theme }) => {
                                // eslint-disable-next-line react/prop-types
                                const color = isFocused
                                    ? theme.colors.primary
                                    : theme.colors.neutral50;
                                return (
                                    <Icon
                                        size="20px"
                                        color={color}
                                        className="icon-lupa"
                                        marginRight="8px"
                                        marginTop="2px"
                                    />
                                );
                            },
                            IndicatorSeparator: null,
                        }}
                        menuIsOpen
                        onChange={onSelectChange}
                        placeholder="Filtrar"
                        theme={addTheme}
                        styles={addCustomStyles}
                        value={value}
                        {...props}
                    />
                </Menu>
            )}
            {isOpen && <Blanket onClick={toggleOpen} />}
        </Container>
    );
}

Select.propTypes = {
    loadingMessage: PropTypes.func,
    noOptionsMessage: PropTypes.func,
    options: PropTypes.array,
    value: (props, propName, componentName) => {
        if (!props[propName]) {
            if (props.isMulti && !Array.isArray(props[propName])) {
                return new Error(
                    `Invalid prop \`${propName}\` of type \`${typeof props[
                        propName
                    ]}\` supplied to \`${componentName}\`, expected \`array\``
                );
            }
            if (typeof props[propName] !== 'object') {
                return new Error(
                    `Invalid prop \`${propName}\` of type \`${typeof props[
                        propName
                    ]}\` supplied to \`${componentName}\`, expected \`object\``
                );
            }
        }

        return null;
    },
    onChange: PropTypes.func,
    formatCreateLabel: PropTypes.func,
    isClearable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isSearchable: PropTypes.bool,
    height: PropTypes.string,
    cacheOptions: PropTypes.bool,
    isMulti: PropTypes.bool,
    defaultOptions: PropTypes.bool,
    defaultValue: PropTypes.object,
    background: PropTypes.string,
    margin: PropTypes.string,
    radius: PropTypes.string,
    type_select: PropTypes.oneOf([
        'default',
        'creatable',
        'async',
        'async_creatable',
    ]),
    required: PropTypes.bool,
    error_message: PropTypes.string,
    styled: PropTypes.string,
    onBlur: PropTypes.func,
    onCreateOption: (props, propName, componentName) => {
        if (props.type_select === 'async_creatable' && !props[propName]) {
            return new Error(
                `${propName} is required in ${componentName} when select is async_creatable`
            );
        }

        return null;
    },
    fontSize: PropTypes.number,
    flex: PropTypes.number,
    minWidth: PropTypes.number,
    width: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    kind: PropTypes.string,
    loadOptions: (props, propName, componentName) => {
        if (
            (props.type_select === 'async' ||
                props.type_select === 'async_creatable') &&
            !props[propName]
        ) {
            return new Error(
                `${propName} is required in ${componentName} when select is async`
            );
        }

        return null;
    },
};

Select.defaultProps = {
    loadingMessage: () => 'Carregando resultados...',
    noOptionsMessage: () => 'Nenhum resultado encontrado',
    formatCreateLabel: (inputValue) => {
        return `Criar "${inputValue}"`;
    },
    options: [],
    isClearable: false,
    isDisabled: false,
    isLoading: false,
    isMulti: false,
    isSearchable: true,
    height: '45px',
    radius: '5px',
    fontSize: 20,
    width: '100%',
    styled: null,
    flex: null,
    id: null,
    size: null,
    minWidth: null,
    value: null,
    name: null,
    placeholder: 'Selecione...',
    required: false,
    type_select: 'default',
    kind: 'normal',
    error_message: null,
    margin: '0 0 30px',
    background: colors.white,
};

export default Select;
