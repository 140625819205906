import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useLocation, useRouteMatch} from 'react-router';

import {Field, Formik} from 'formik';
import moment from 'moment';
import * as yup from 'yup';

import {AppContext} from '../../../app/app-container';
import {Row} from '../../../app/global-styles';
import {error, loading, success} from '../../../components/alerts';
import Button from '../../../components/button';
import ControlledInput from '../../../components/form-components/controlled-input';
import 'moment/locale/pt-br';
import PageContainer from '../../../components/page-container';
import Request from '../../../utils/Request';
import {pathname2Query} from '../../../utils/RouterRes';
import {parser} from '../../../utils/Select';
import {screens} from '../../../utils/Theme';
import {StyledApp} from "../../../app/app-container/styles";
import Loader from "../../../components/tableloader";

moment.locale('pt-br');

const formValues = {
    sexo: '',
    faixa_etaria: '',
    estado: {},
    cidade: {},
    bairro: '',
    bairro_: {},
    vereador: {},
    escolaridade: '',
    pergunta_1: '',
    pergunta_2: '',
    pergunta_3: '',
    pergunta_4: '',
    pergunta_5: '',
    pergunta_6: '',
    pergunta_7: '',
    pergunta_8: '',
    pergunta_9: '',
    pergunta_10: '',
    pergunta_11: '',
    pergunta_12: '',
    geolocalizacao: [],
};

function Pesquisa() {
    const location = useLocation();
    const [configs, setConfigs] = useState()
    const [loader, setLoader] = useState(true)
    const match = useRouteMatch();
    const url_params = pathname2Query(location.pathname, match.url);
    const history = useHistory();
    const initialValues = formValues;
    const data_inicio = new Date();
    const {screenSize} = useContext(AppContext);

    useEffect(() => {
        getConfigs().then();

    }, []);

    const getConfigs = async () => {

        const request = new Request();
        const req_cidade_config = request.setRequest(
            'estados',
            'get_cidade_estado_config',
            {}
        );

        const result = await request.execute();
        const proccessed = await result[req_cidade_config];
        setConfigs(proccessed);
        setLoader(false)
    }

    async function handleSubmit(values, state) {
        const request = new Request();
        const pesquisa_to_save = {};

        pesquisa_to_save.faixa_etaria = values.faixa_etaria;
        pesquisa_to_save.escolaridade = values.escolaridade;
        pesquisa_to_save.pergunta_1 = values.pergunta_1;
        pesquisa_to_save.pergunta_2 = values.pergunta_2;
        pesquisa_to_save.pergunta_3 = values.pergunta_3;
        pesquisa_to_save.pergunta_4 = values.pergunta_4;
        pesquisa_to_save.pergunta_5 = values.pergunta_5;
        pesquisa_to_save.pergunta_6 = values.pergunta_6;
        pesquisa_to_save.pergunta_7 = values.pergunta_7;
        pesquisa_to_save.pergunta_8 = values.pergunta_8;
        pesquisa_to_save.pergunta_9 = values.pergunta_9;
        pesquisa_to_save.pergunta_10 = values.pergunta_10;
        pesquisa_to_save.pergunta_11 = values.pergunta_11;
        pesquisa_to_save.pergunta_12 = values.pergunta_12;
        pesquisa_to_save.sexo = values.sexo;
        pesquisa_to_save.data_fim = new Date();

        if (values.estado.value) {
            pesquisa_to_save.estado = values.estado.value;
        } else {
            pesquisa_to_save.estado = null;
        }

        if (values.cidade.value) {
            pesquisa_to_save.cidade = values.cidade.value;
        } else {
            pesquisa_to_save.cidade = null;
        }

        //if (values.data) {
        pesquisa_to_save.data = moment(data_inicio).format(
            'YYYY-MM-DD HH:mm:ss'
        );
        //}

        if (values.bairro.value) {
            pesquisa_to_save.bairro = values.bairro.value;
        } else if (values.bairro) {
            pesquisa_to_save.bairro = values.bairro;
        } else {
            pesquisa_to_save.bairro = null;
        }

        if (values.vereador.value) {
            pesquisa_to_save.vereador = values.vereador.value;
        } else {
            pesquisa_to_save.vereador = null;
        }

        if (values.geolocalizacao.length >= 2) {
            pesquisa_to_save.geolocalizacao = values.geolocalizacao.join(', ');
        } else {
            pesquisa_to_save.geolocalizacao = null;
        }

        const loadToast = loading('Salvando pesquisa');

        try {
            const req_pesquisa = request.setRequest('pesquisas', 'salvar', {
                pesquisa: pesquisa_to_save,
            });

            const result = await request.execute();

            if (result[req_pesquisa] === true) {
                loadToast();
                success(`Pesquisa alterada com sucesso`);
                state.setSubmitting(false);
                state.resetForm();
            } else if (Number(result[req_pesquisa]) > 0) {
                loadToast();
                success(`Pesquisa adicionada com sucesso`);
                state.setSubmitting(false);
                state.resetForm();
            } else {
                loadToast();
                error('Não foi possível salvar pesquisa!');
                state.setSubmitting(false);
            }

            history.push('/pesquisas');
        } catch (e) {
            loadToast();
            error('Falha ao salvar pesquisa!');
            state.setSubmitting(false);
        }
    }

    async function getEstadosSelect(search) {
        const request = new Request();

        const req_estados = request.setRequest('estados', 'listar', {search});
        const result = await request.execute();

        const {dados} = result[req_estados] || [];

        return parser('descricao', 'id', dados);
    }

    async function getCidadesSelect(search, estado_id) {
        if (estado_id && Number(estado_id) > 0) {
            const request = new Request();

            const req_cidades = request.setRequest('cidades', 'listar', {
                search,
                estado_id,
            });
            const result = await request.execute();

            const {dados} = result[req_cidades] || [];

            return parser('descricao', 'id', dados);
        }
        return [];
    }

    async function getBairrosOptions() {
        const request = new Request();

        const req_cidade_config = request.setRequest(
            'estados',
            'get_cidade_estado_config',
            {}
        );

        const result = await request.execute();
        const cidade_id = result[req_cidade_config].id

        if (cidade_id && Number(cidade_id) > 0) {
            return getBairrosSelect(null, cidade_id)
        }
        return [];
    }

    async function getBairrosSelect(search, cidade_id) {
        if (cidade_id && Number(cidade_id) > 0) {
            const request = new Request();

            const req_bairros = request.setRequest('bairros', 'listar', {
                search,
                cidade_id,
            });
            const result = await request.execute();

            const {dados} = result[req_bairros] || [];

            return parser('descricao', 'id', dados);
        }
        return [];
    }

    async function handleBairroCreate(input_value, formState, input_name) {
        if (formState.values.cidade.value) {
            const request = new Request();

            const req_new_bairro = request.setRequest('bairros', 'salvar', {
                bairro: {
                    descricao: input_value,
                    cidade: formState.values.cidade.value,
                },
            });

            const result = await request.execute();

            if (result && result[req_new_bairro]) {
                formState.setFieldValue(input_name, {
                    label: input_value,
                    value: result[req_new_bairro],
                });
            }
        } else {
            error(
                'Necessário selecionar a cidade antes de adicionar um novo bairro!',
                'Erro!'
            );
        }
    }

    async function getVereadorSelect(search) {
        const request = new Request();

        const req_vereadores = request.setRequest('vereadores', 'listar', {
            search
        });
        const result = await request.execute();

        const {dados} = result[req_vereadores] || [];

        return parser('nome', 'id', dados);
    }

    async function handleVereadorCreate(input_value, formState, input_name) {
        const request = new Request();

        const req_new_vereador = request.setRequest('vereadores', 'salvar', {
            vereador: {
                nome: input_value
            },
        });

        const result = await request.execute();

        if (result && result[req_new_vereador]) {
            formState.setFieldValue(input_name, {
                label: input_value,
                value: result[req_new_vereador],
            });
        }
    }

    function makeForm(formState) {
        return (
            <form onSubmit={formState.handleSubmit}>

                <div style={{opacity: 0, position: "absolute", top: "-100%", left: "-100%"}}>
                    <Field
                        component={ControlledInput}
                        name="geolocalizacao"
                        maxLength={255}
                        label="Geolocalizaçao (Latitude, Longitude)"
                        type="geolocation"
                        size={4}
                        disabled
                    />
                </div>

                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        options={[
                            {label: 'Masculino', value: 'M'},
                            {label: 'Feminino', value: 'F'},
                        ]}
                        size={3}
                        buttonstyle
                        type="input-radio"
                        name="sexo"
                        label="Sexo"
                        required
                        ignoremobile
                    />
                    <Field
                        component={ControlledInput}
                        options={[
                            {label: 'Menor que 25 anos', value: 'Menor que 25 anos'},
                            {label: '25 à 44 anos', value: '25 à 44 anos'},
                            {label: 'Acima de 45 anos', value: 'Acima de 45 anos'},
                        ]}
                        size={5}
                        buttonstyle
                        required
                        type="input-radio"
                        name="faixa_etaria"
                        label="Faixa Etária"
                        ignoremobile
                    />
                </Row>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        size={8}
                        buttonstyle
                        required
                        type="input-radio"
                        name="bairro"
                        label="Bairro"
                        ignoremobile
                        defaultOptions
                        cacheOptions
                        loadOptions={() => getBairrosOptions()}
                    />
                </Row>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        options={[
                            {label: "Alex do Gás", value: "Alex do Gás"},
                            {label: "Baltazar", value: "Baltazar"},
                            {label: "Branco/Nulo/Não Avaliou", value: "Branco/Nulo/Não Avaliou"},
                            {label: "Indecisos/Não sabe", value: 'Indecisos/Não sabe'},
                        ]}
                        size={4}
                        buttonstyle
                        required
                        type="input-radio"
                        name="pergunta_1"
                        label="Se as eleições fossem hoje, em qual destes candidatos você votaria?"
                        ignoremobile
                    />
                    <Field
                        component={ControlledInput}
                        options={[
                            {label: 'Está definido', value: 'Definido'},
                            {label: 'Pode mudar', value: 'Pode mudar'},
                        ]}
                        size={3}
                        buttonstyle
                        type="input-radio"
                        name="pergunta_2"
                        label="Seu voto está definido ou ainda pode mudar?"
                        required
                        ignoremobile
                    />
                </Row>
                <Row
                    ignoreMobile
                    contentEnd
                >
                    {url_params.id > 0 && (
                        <Button
                            type="reset"
                            kind="cancel"
                            margin="0 10px 0 0"
                            onClick={() => {
                                formState.resetForm();
                                history.push('/pesquisas/editor');
                            }}
                        >
                            Cancelar
                        </Button>
                    )}
                    <Button
                        type="submit"
                        kind="save"
                        disabled={formState.isSubmitting}
                        style={{display: 'flex', justifyContent: 'center'}}
                        width={screenSize === screens.smartphone ? '100%' : '110px'}
                    >
                        Salvar
                    </Button>
                </Row>
            </form>
        );
    }

    function renderForm() {
        return (
            <PageContainer title={"Pesquisa Política: " + configs.cidade}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={yup.object().shape({
                        sexo: yup.string().required('Campo obrigatório'),
                        faixa_etaria: yup.string().required('Campo obrigatório'),
                        estado: yup.object(),
                        cidade: yup.object(),
                        bairro: yup.string().required('Campo obrigatório'),
                        // bairro_: yup.object().validSelect('Bairro é obrigatório'),
                        // vereador: yup.object().validSelect('Vereador é obrigatório'),
                        geolocalizacao: yup.array(),
                        pergunta_1: yup.string().required('Resposta obrigatória'),
                        pergunta_2: yup.string().required('Resposta obrigatória'),
                    })}
                    enableReinitialize
                    onSubmit={handleSubmit}
                >
                    {makeForm}
                </Formik>
            </PageContainer>
        );
    }

    return (
        <PageContainer padding scroll>
            <StyledApp>
                <Loader style={{minHeight: '150px', position: "relative"}} loading={loader}/>
            </StyledApp>
            {!loader &&
            renderForm()
            }
        </PageContainer>
    );
}

export default Pesquisa;
