import React from 'react';
import ReactDOM from 'react-dom';

import App from './app';
import { URL_API } from './data/constants';
import Request from './utils/Request';

import './utils/yupApplyValidators';
import './assets/fonts/icons/styles.css';

Request.setDefaultUrl(URL_API);

ReactDOM.render(<App />, document.getElementById('root'));
