import React, { useContext } from 'react';
import { useLocation, useRouteMatch } from 'react-router';

import { AppContext } from '../../app/app-container';
import { Bar } from '../../app/global-styles';
import { getMenu, isActive } from '../../utils/Menu';
import Icon from '../icon';
import { Container, Menu, NavMenu, StyledLink, LinkContainer } from './styles';

function Sidebar() {
    const { menu, hasPermissao } = useContext(AppContext);
    const location = useLocation();
    const routeMatch = useRouteMatch();

    return (
        <Container>
            <Menu>
                <Icon className="icon-menu" size="18px" marginRight="10px" />
                Menu
            </Menu>
            <NavMenu>
                {getMenu(menu, hasPermissao).map((item) => {
                    if (!item.hidden) {
                        const active = isActive(
                            item.link,
                            location,
                            routeMatch
                        );
                        return (
                            <StyledLink
                                to={item.link}
                                title={item.title}
                                key={item.link}
                                active={active}
                            >
                                {active && <Bar orientation="vertical" />}
                                <LinkContainer active={active}>
                                    <Icon
                                        className={item.icon}
                                        marginRight="10px"
                                        size="18px"
                                    />
                                    {item.title}
                                </LinkContainer>
                            </StyledLink>
                        );
                    }
                    return null;
                })}
            </NavMenu>
        </Container>
    );
}

export default Sidebar;
