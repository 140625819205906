import React, {useContext, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router';

import { Field, Formik } from 'formik';
import moment from 'moment';

import { Row } from '../../../app/global-styles';
import ControlledInput from '../../../components/form-components/controlled-input';
import PageContainer from '../../../components/page-container';
import Table from '../../../components/table';
import Request, { getLimit } from '../../../utils/Request';
import 'moment/locale/pt-br';
import Icon from "../../../components/icon";
import {colors} from "../../../configs/theme";
import Button from "../../../components/button";
import {screens} from "../../../utils/Theme";
import {AppContext} from "../../../app/app-container";

moment.locale('pt-br');

function Pessoas() {
    const history = useHistory();
    const tableRef = useRef();
    const [searchParams, setSearchParams] = useState({});
    const {screenSize} = useContext(AppContext);

    const initialFilters = {
        search: '',
    };

    useEffect(() => {
        tableRef.current.fireFetchData();
    }, [searchParams]);

    async function getPesquisas({ page, limit: table_limit }) {
        const request = new Request();

        const limit = getLimit(page, table_limit);

        const req_pesquisas = request.setRequest('pesquisas', 'listar', {
            limit,
            ...searchParams,
        });
        const result = await request.execute();

        if (result && result[req_pesquisas]) {
            return result[req_pesquisas];
        }
        return {};
    }

    function makeFormFiltros(formikState) {
        return (
            <Row as="form" wrap="wrap">
                <Button
                    size={1}
                    type="button"
                    margin={screenSize === screens.smartphone ? '0 0 20px' : '0 10px 0 0'}
                    onClick={() => {
                        history.push('/pesquisas/editor');
                    }}
                >
                    ADICIONAR
                </Button>
                <Field
                    name="search"
                    type="text"
                    placeholder="Filtrar"
                    size={1}
                    minWidth={210}
                    onChange={(e) => {
                        const { value, name } = e.target;
                        setSearchParams({ ...searchParams, [name]: value });
                    }}
                    icon="icon-lupa"
                    kind="filtros"
                    component={ControlledInput}
                />
                {Object.keys(searchParams).some(
                    (key) => searchParams[key] && searchParams[key] !== ''
                ) && (
                    <Icon
                        className="icon-limpar"
                        size="26px"
                        color={colors.grey_placeholder}
                        title="Limpar filtros"
                        onClick={() => {
                            setSearchParams({});
                            formikState.resetForm({ ...initialFilters });
                        }}
                        hover={colors.white}
                        marginLeft="15px"
                        kind="action-button"
                    />
                )}
            </Row>
        );
    }

    return (
        <PageContainer padding scroll>
            <Row margin="0 0 21px 0">
                <Formik
                    onSubmit={() => {}}
                    initialValues={{ ...initialFilters }}
                    validationSchema={{}}
                >
                    {makeFormFiltros}
                </Formik>
            </Row>
            <Table
                headers={[
                    {
                        name: 'Bairro',
                        accessor: 'bairro',
                    },
                    {
                        name: 'Total',
                        accessor: 'total',
                    },
                ]}
                options={() => {}}
                tableRef={tableRef}
                data_function={getPesquisas}
                clickHandler={() => {}}
            />
        </PageContainer>
    );
}

export default Pessoas;
