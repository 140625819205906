import React, {useContext, useEffect, useState, useCallback} from 'react';

import Map from 'pigeon-maps';
import Marker from 'pigeon-marker';
import PropTypes from 'prop-types';

import {AppContext} from '../../../app/app-container';
import {Row} from '../../../app/global-styles';
import {colors} from '../../../configs/theme';
import {screens} from '../../../utils/Theme';
import Button from '../../button';
import Input from '../input';
import {Container, MapContainer, IconsContainer} from './styles';

function mapTilerProvider(x, y, z) {
    const s = String.fromCharCode(97 + ((x + y + z) % 3));
    return ` https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`;
}

function Geolocation({setFieldValue, value, width, size, ...props}) {
    const [mapZoom, setMapZoom] = useState(14);
    const [coordinates, setCoordinates] = useState([0, 0]);
    const [isMapOpen, setIsMapOpen] = useState(false);
    const {screenSize} = useContext(AppContext);
    const [mapWidthHeight, setMapWidthHeight] = useState({
        width: 600,
        height: 400,
    });

    function handleMapClick({latLng}) {
        setCoordinates(latLng);
    }

    function handleSaveLocation() {
        setFieldValue(props.name, coordinates);
    }

    function requestGetLocation(mounting = false) {
        navigator.geolocation.watchPosition(
            (position) => {
                const {latitude, longitude} = position.coords;

                if (mounting) {
                    setFieldValue(props.name, [latitude, longitude]);
                }

                setCoordinates([latitude, longitude]);
            },
            (err) => {
                console.log(err);
            },
            {
                enableHighAccuracy: true,
                maximumAge: 300000,
                timeout: 300000
            }
        );
    }

    function _requestGetLocation(mounting = false) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const {latitude, longitude} = position.coords;

                if (mounting) {
                    setFieldValue(props.name, [latitude, longitude]);
                }

                setCoordinates([latitude, longitude]);
            },
            (err) => {
                // eslint-disable-next-line no-console
                console.log(err);
            },
            {
                timeout: 30000,
            }
        );
    }

    useEffect(() => {
        requestGetLocation(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setCoordinates(value.map((val) => Number(val)));
    }, [value]);

    const getWindowWidth = useCallback(() => {
        if (screenSize === screens.laptop) {
            setMapWidthHeight({
                width: window.innerWidth * 0.54,
                height: window.innerWidth * 0.4,
            });
        } else if (screenSize === screens.tablet) {
            setMapWidthHeight({
                width: 600,
                height: 400,
            });
        } else if (screenSize === screens.smartphone) {
            setMapWidthHeight({
                width: window.innerWidth - 20,
                height: window.innerHeight - 66,
            });
        }
    }, [screenSize]);

    useEffect(() => {
        window.addEventListener('resize', getWindowWidth);

        return () => {
            window.removeEventListener('resize', getWindowWidth);
        };
    }, [screenSize, getWindowWidth]);

    useEffect(() => {
        getWindowWidth();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Input
                {...props}
                width={width}
                value={value.join(', ')}
                type="text"
                size={size}
                iconFiltros="icon-geolocation"
                show_filtros
                iconFiltrosColor={colors.grey_chumbo_icons}
                onClickFiltros={() => setIsMapOpen(true)}
            />
            <Container
                forceMobile={screenSize === screens.smartphone}
                hidden={isMapOpen}
                width={mapWidthHeight.width}
                height={mapWidthHeight.height}
            >
                <MapContainer>
                    <Map
                        center={coordinates}
                        zoom={mapZoom}
                        width={mapWidthHeight.width}
                        height={mapWidthHeight.height}
                        provider={mapTilerProvider}
                        onClick={handleMapClick}
                        attribution={<></>}
                    >
                        {coordinates.length === 2 && (
                            <Marker
                                anchor={coordinates}
                                payload={1}
                                onClick={() => {
                                }}
                            />
                        )}
                    </Map>
                    <IconsContainer>
                        <Button
                            icon="icon-geolocation"
                            margin="0 0 0 5px"
                            kind="actions"
                            title="Centralizar"
                            marginIcon="0"
                            onClick={() => requestGetLocation()}
                        />
                        <Button
                            icon="icon-minus"
                            margin="0 0 0 5px"
                            kind="actions"
                            title="Zoom out"
                            marginIcon="0"
                            onClick={() => setMapZoom(mapZoom - 1)}
                        />
                        <Button
                            icon="icon-plus"
                            margin="0 0 0 5px"
                            title="Zoom"
                            kind="actions"
                            marginIcon="0"
                            onClick={() => setMapZoom(mapZoom + 1)}
                        />
                    </IconsContainer>
                </MapContainer>
                <Row contentEnd margin="10px 0 0 0" ignoreMobile>
                    <Button
                        type="reset"
                        kind="cancel"
                        margin="0 10px 0 0"
                        onClick={() => setIsMapOpen(false)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        type="button"
                        kind="save"
                        onClick={() => {
                            setIsMapOpen(false);
                            handleSaveLocation();
                        }}
                    >
                        Salvar Localização
                    </Button>
                </Row>
            </Container>
        </>
    );
}

Geolocation.propTypes = {
    setFieldValue: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ).isRequired,
};

Geolocation.defaultProps = {
    size: null,
};

export default Geolocation;
