import React from 'react';

import { JsonEditor as EditorJ } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import './styles.css';

// eslint-disable-next-line react/prop-types
function JsonEditor({ value, ...props }) {
    const json = JSON.parse(value);

    let width = (100 / 8) * Number(props.size);

    return (
        <div
            style={{
                width: width + '%',
                padding: '0 16px 20px 16px',
            }}
        >
            <EditorJ value={json} {...props} />
        </div>
    );
}

export default JsonEditor;
