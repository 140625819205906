export const menu = [
    {
        icon: 'icon-home',
        link: '/',
        title: 'Início',
    },
    {
        icon: 'icon-tipo-materia',
        link: '/pesquisas',
        title: 'Pesquisas',
    },
    {
        link: '/configuracoes',
        title: 'Configurações',
        hidden: true,
    },
    {
        link: '/usuarios',
        title: 'Usuários',
        hidden: true,
    },
];
