import { extractParamsAsString } from '../utils/RouterRes';

export const groups = {
    '/': [
        {
            title: 'Página inicial',
            to: '/',
            permissao: 'R_DIARIOS',
        },
    ],
    pesquisas: [
        {
            title: 'Todas as pesquisas',
            to: '/pesquisas',
            permissao: 'R_DIARIOS',
        },
        {
            title: 'Adicionar pesquisa',
            to: '/pesquisas/editor',
            permissao: 'M_DIARIOS',
        },
    ],
    usuarios: [

    ]
};

export const getTabGroup = (pathname, url, this_item = null) => {
    const { group_name, group_param, action_param } = getParams(pathname, url);
    const full_url = getFullUrl(this_item, group_param, action_param);

    const group = getGroup(group_name);

    return selectedTabs(group, full_url);
};

const getFullUrl = (this_item, group_param, action_param) => {
    return this_item || `${group_param}${action_param}`;
};

export const getGroupInfo = (menu = [], pathname, url) => {
    const { group_param } = getParams(pathname, url);
    return menu.find((item) => item.link === group_param);
};

const selectedTabs = (group, full_url) => {
    if (group.length > 0) {
        return group.map((tab) => {
            return {
                ...tab,
                selected: tab.to === full_url,
            };
        });
    }

    return [];
};

const getParams = (pathname, url) => {
    const params_string = extractParamsAsString(pathname, url);

    const params = params_string.split('/');

    const group_name = params[0] ? params[0] : '/';
    const group_param = params[0] ? `/${params[0]}` : '/';
    const action_param = params[1] ? `/${params[1]}` : '';

    return {
        group_name,
        group_param,
        action_param,
    };
};

const getGroup = (group_name) => {
    const group_index = Object.keys(groups).find(
        (group) => group === group_name
    );

    if (groups[group_index] && groups[group_index].length > 0) {
        return groups[group_index];
    }

    return [];
};

export const isSomeActive = (requested_group, pathname, url) => {
    const { group_name } = getParams(pathname, url);
    const { group_name: requested_group_name } = getParams(
        requested_group,
        url
    );

    if (group_name === requested_group_name) {
        const group = selectedTabs(getGroup(group_name), requested_group);
        return group.some((item) => item.selected);
    }
    return false;
};

export const getPathPage = (menu, group, tabs, actual_url) => {
    const splitted = group.link.split('/');

    if (splitted && !splitted[1] && splitted[1] === '') {
        splitted.pop();
    }

    const pathItens = splitted.map((item) => {
        const groupInfo = getGroupInfo(menu, `/${item}`, '/');

        return {
            link: groupInfo.link,
            title: groupInfo.title,
        };
    });
    const { group_name } = getParams(group.link, '/');
    const actualGroup = getGroup(group_name);
    const actualTab = selectedTabs(actualGroup, actual_url).find(
        (tab) => tab.selected
    );

    if (actualTab) {
        pathItens.push({
            link: actualTab.to,
            title: actualTab.title,
        });
    }

    return pathItens;
};
