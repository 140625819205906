import React, {useContext, useEffect, Suspense, lazy, useState} from 'react';
import {Link, Route, Switch, useHistory, useLocation} from 'react-router-dom';

import favicon from '../../assets/img/favicon-nucleo.ico';
import {error} from '../../components/alerts';
import Error from '../../components/error';
import Header, {HeaderRight} from '../../components/header';
import HeaderMobile from '../../components/header-mobile';
import Icon from '../../components/icon';
import Sidebar from '../../components/sidebar';
import {colors} from '../../configs/theme';
import Configuracoes from '../../pages/painel/configuracoes';
import Home from '../../pages/painel/home';
import Pesquisa from '../../pages/painel/pesquisa';
import Pesquisas from '../../pages/painel/pesquisas';
import Usuarios from '../../pages/painel/usuarios';
import {screens} from '../../utils/Theme';
import {AppContext} from '../app-container';
import PrivateRouter from './router/private-router';
import {AppContent, Container, FullContainer} from './styles';
import Loader from "../../components/tableloader";
import Request from "../../utils/Request";
// import Custom from '../../pages/painel/custom';
const Custom = lazy(() => import('../../pages/painel/custom'));

function Painel() {
    const {logout, screenSize, setIsMenuOpen} = useContext(AppContext);
    const history = useHistory();
    const location = useLocation();
    const [customForm, setCustomForm] = useState("nao");

    useEffect(() => {
        if (history.action === 'REPLACE') {
            error('Você não tem permissão para acessar essa página!');
        }
    }, [history.action]);

    async function getConfigs() {
        const request = new Request();
        const req_configs = request.setRequest(
            'configuracoes',
            'listar',
            {}
        );
        const promisse_configs = await request.execute();
        const result_configs = await promisse_configs[req_configs];

        setCustomForm(result_configs?.custom_form)
    }

    useEffect(() => {
        setIsMenuOpen(false);
        getConfigs().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        const link =
            document.querySelector("link[rel*='icon']") ||
            document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = favicon;
        document.getElementsByTagName('head')[0].appendChild(link);
    }, []);

    return (
        <Route>
            <FullContainer>
                <Sidebar/>
                <Container direction="column" content="true">
                    {screenSize === screens.laptop ? (
                        <Header>
                            <HeaderRight>
                                <Link to="/usuarios" title="Usuários">
                                    <Icon
                                        className="icon-user"
                                        size="20px"
                                        pointer
                                        color={colors.grey_placeholder}
                                    />
                                </Link>
                                <Link to="/configuracoes" title="Configurações">
                                    <Icon
                                        className="icon-config"
                                        size="20px"
                                        pointer
                                        color={colors.grey_placeholder}
                                    />
                                </Link>
                                <button
                                    title="Sair"
                                    type="button"
                                    onClick={(e) => logout && logout(e)}
                                >
                                    <Icon
                                        className="icon-power-off"
                                        size="20px"
                                        pointer
                                        color={colors.grey_placeholder}
                                    />
                                </button>
                            </HeaderRight>
                        </Header>
                    ) : (
                        <HeaderMobile/>
                    )}

                    <AppContent>
                        <Suspense fallback={<Loader loading={true}/>}>
                            <Switch>
                                <PrivateRouter
                                    publica
                                    path="/usuarios"
                                    component={Usuarios}
                                />
                                <PrivateRouter
                                    publica
                                    path="/pesquisas/editor"
                                    component={customForm === 'nao' ? Pesquisa : Custom}
                                />
                                <PrivateRouter
                                    publica
                                    path="/pesquisas"
                                    component={Pesquisas}
                                />
                                <PrivateRouter
                                    publica
                                    path="/configuracoes"
                                    component={Configuracoes}
                                />
                                <PrivateRouter publica path="/" component={Home}/>
                                <Route component={Error}/>
                            </Switch>
                        </Suspense>
                    </AppContent>
                </Container>
            </FullContainer>
        </Route>
    );
}

export default Painel;
