import React, { useContext, useEffect, useState } from 'react';

import { Field, Formik } from 'formik';
import moment from 'moment';

import { AppContext } from '../../../app/app-container';
import { Row } from '../../../app/global-styles';
import { error, loading as loader, success } from '../../../components/alerts';
import Button from '../../../components/button';
import JsonEditor from '../../../components/form-components/editorjson';
import ControlledInput from '../../../components/form-components/controlled-input';
import PageContainer from '../../../components/page-container';
import TableLoader from '../../../components/tableloader';
import Request from '../../../utils/Request';
import { parser } from '../../../utils/Select';
import 'moment/locale/pt-br';
import { screens } from '../../../utils/Theme';

moment.locale('pt-br');

const formValues = {
    bairro: {},
    cidade: {},
    custom_form: '',
    custom_form_json: '',
};

function Pessoa() {
    const [configuracoes, setConfiguracoes] = useState({});
    const [loading, setLoading] = useState(true);
    const { screenSize } = useContext(AppContext);
    const initialValues =
        configuracoes && Object.keys(configuracoes).length > 0
            ? configuracoes
            : { ...formValues };

    useEffect(() => {
        async function getConfigs() {
            const request = new Request();

            const req_configs = request.setRequest(
                'configuracoes',
                'listar',
                {}
            );

            const result = await request.execute();

            if (result && result[req_configs]) {
                const configs_to_edit = {bairro:{}};

                const { cidade } = result[req_configs];

                configs_to_edit.custom_form = result[req_configs].custom_form;
                configs_to_edit.custom_form_json =
                    result[req_configs].custom_form_json;

                const req_cidade = request.setRequest('cidades', 'listar', {
                    id: cidade,
                });

                const result_cidade = await request.execute();

                if (
                    result_cidade &&
                    result_cidade[req_cidade] &&
                    result_cidade[req_cidade].dados &&
                    result_cidade[req_cidade].dados.id
                ) {
                    configs_to_edit.cidade = {
                        label: result_cidade[req_cidade].dados.descricao,
                        value: cidade,
                    };
                } else {
                    configs_to_edit.cidade = {};
                }

                setConfiguracoes(configs_to_edit);
                setLoading(false);
            }
        }

        getConfigs();
    }, []);

    async function handleSubmit(values, state) {
        const request = new Request();
        const configs_to_save = {};

        if (values.cidade.value) {
            configs_to_save.cidade = values.cidade.value;
        }

        configs_to_save.custom_form = values.custom_form;
        configs_to_save.custom_form_json = values.custom_form_json;

        const loadToast = loader('Salvando configurações');

        try {
            const req_pessoa = request.setRequest('configuracoes', 'salvar', {
                ...configs_to_save,
            });

            const result = await request.execute();

            if (result[req_pessoa] === true) {
                loadToast();
                success(`Configurações salvas`);
                state.setSubmitting(false);
            } else {
                loadToast();
                error('Não foi possível salvar configurações!');
                state.setSubmitting(false);
            }
        } catch (e) {
            loadToast();
            error('Falha ao salvar configurações!');
            state.setSubmitting(false);
        }
    }

    async function getCidadesSelect(search) {
        const request = new Request();

        const req_tags = request.setRequest('cidades', 'listar', { search });
        const result = await request.execute();

        const { dados } = result[req_tags] || [];

        return parser('descricao', 'id', dados);
    }

    function handleJsonEditor(target, formState) {
        formState.setFieldValue('custom_form_json', JSON.stringify(target));
    }

    async function getBairrosSelect(search, cidade_id) {
        if (cidade_id && Number(cidade_id) > 0) {
            const request = new Request();

            const req_bairros = request.setRequest('bairros', 'listar', {
                search,
                cidade_id,
            });
            const result = await request.execute();

            const {dados} = result[req_bairros] || [];

            return parser('descricao', 'id', dados);
        }
        return [];
    }

    async function handleBairroCreate(input_value, formState, input_name) {
        if (formState.values.cidade.value) {
            const request = new Request();

            const req_new_bairro = request.setRequest('bairros', 'salvar', {
                bairro: {
                    descricao: input_value,
                    cidade: formState.values.cidade.value,
                },
            });

            const result = await request.execute();

            if (result && result[req_new_bairro]) {
                // formState.setFieldValue(input_name, {
                //     label: input_value,
                //     value: result[req_new_bairro],
                // });
            }
        } else {
            error(
                'Necessário selecionar a cidade antes de adicionar um novo bairro!',
                'Erro!'
            );
        }
    }
    function makeForm(formState) {

        return (
            <form onSubmit={formState.handleSubmit}>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        name="cidade"
                        isClearable
                        type_select="async"
                        type="select"
                        label="Cidade Padrão"
                        placeholder="Cidade Padrão"
                        size={4}
                        defaultOptions
                        cacheOptions
                        required
                        loadOptions={(search) => getCidadesSelect(search)}
                        onChange={(value) => {
                            if (
                                value.value &&
                                formState.values.cidade.value &&
                                value.value !== formState.values.cidade.value
                            ) {
                                formState.setFieldValue('bairro', {});
                            } else if (!value.value) {
                                formState.setFieldValue('bairro', {});
                            }
                        }}
                    />

                    <Field
                        component={ControlledInput}
                        name="bairro"
                        isClearable
                        disabled={!formState.values.cidade.value}
                        type_select="async_creatable"
                        type="select"
                        onCreateOption={(input_value) =>
                            handleBairroCreate(input_value, formState, 'bairro')
                        }
                        label="Bairro"
                        placeholder="Bairro"
                        size={4}
                        defaultOptions
                        position={screenSize === screens.smartphone ? 'relative' : ''}
                        cacheOptions
                        required
                        loadOptions={(search) =>
                            getBairrosSelect(
                                search,
                                formState.values.cidade.value
                            )
                        }
                    />

                    <Field
                        component={ControlledInput}
                        options={[
                            { label: 'Sim', value: 'sim' },
                            { label: 'Não', value: 'nao' },
                        ]}
                        size={4}
                        buttonstyle
                        type="input-radio"
                        name="custom_form"
                        label="Formulário Customizado"
                        required={true}
                        ignoremobile
                    />
                </Row>
                <Row height="auto" spaceBetween>
                    <Field
                        component={JsonEditor}
                        size={4}
                        value={formState.initialValues.custom_form_json||'{}'}
                        buttonstyle
                        name="custom_form_json"
                        ignoremobile
                        label="Dados do Form (JSON)"
                        onChange={(target) =>
                            handleJsonEditor(target, formState)
                        }
                    />
                </Row>
                {/*<Row height="auto" spaceBetween>*/}
                {/*    <Field*/}
                {/*        component={ControlledInput}*/}
                {/*        size={8}*/}
                {/*        buttonstyle*/}
                {/*        type="text"*/}
                {/*        as="textarea"*/}
                {/*        height="400px"*/}
                {/*        name="custom_form_json"*/}
                {/*        label="Dados do Form (JSON)"*/}
                {/*        ignoremobile*/}
                {/*    />*/}
                {/*</Row>*/}
                <Row
                    contentEnd
                    padding={screenSize === screens.smartphone ? '0' : '0 15px'}
                    ignoreMobile
                >
                    <Button
                        style={{marginBottom: '40px'}}
                        type="submit"
                        kind="save"
                        disabled={formState.isSubmitting}
                    >
                        Salvar
                    </Button>
                </Row>
            </form>
        );
    }

    return (
        <PageContainer title="Configurações" padding>
            {loading ? (
                <TableLoader loading={loading} left="calc(50% + 50px)" />
            ) : (
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={handleSubmit}
                >
                    {makeForm}
                </Formik>
            )}
        </PageContainer>
    );
}

export default Pessoa;
